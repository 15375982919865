*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --back-color: white;
  --primary-color: #dba87e;
  --secondary-color: #ffffff;
  --text-color: #fff;
}

body {
  background-color: white !important;
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Circular Std Black";
  src: url(./fonts/CircularStd-Black.otf);
}

@font-face {
  font-family: "Circular Std Bold";
  src: url(./fonts/CircularStd-Bold.otf);
}

@font-face {
  font-family: "Circular Std Book";
  src: url(./fonts/CircularStd-Book.otf);
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url(./fonts/Montserrat-Bold.otf);
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url(./fonts/Montserrat-Regular.otf);
}
@font-face {
  font-family: "SourceSansPro-Bold";
  src: url(./fonts/SourceSansPro-Bold.otf);
}
